import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NoSsr from "@mui/material/NoSsr";
import Zoom from "@mui/material/Zoom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useTheme } from "@mui/material/styles";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(
    theme.breakpoints.up("md"),
    { defaultMatches: true }
  );
  
  const [openSidebar, setOpenSidebar] = useState(false);
  
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };
  
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  
  const open = isMd ? false : openSidebar;
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  
  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }
      window.scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" });
    });
  };
  
  return (
    <Box 
      id="page-top"
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100%'
      }}
    >
      <Header onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
      />
      <Box
        maxWidth={{ sm: 720, md: 1236 }}
        width={1}
        margin={'0 auto'}
        paddingX={2}
        //paddingY={{ xs: 4, sm: 6, md: 8 }}
        paddingY={2}
      >
        {children}
        <Divider />
      </Box>
      <Footer />
      <NoSsr>
        <Zoom in={trigger}>
          <Box
            onClick={() => scrollTo("page-top")}
            role="presentation"
            sx={{ position: "fixed", bottom: 24, right: 32 }}
          >
            <Fab 
              color="primary" 
              size="small" 
              aria-label="scroll back to top"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main,
                  border: "2px solid" + theme.palette.primary.main,
                },
              }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
      </NoSsr>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;